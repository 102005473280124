export const endpointsScheduled = {
  getRpaConfigByRpaId: '/rpa/scheduler/rpa-config/:rpaId',
} as const

export const endpointsRpaRun = {
  getHistory: '/rpa/run/history',
  getNextExecution: '/rpa/run/next-execution',
  getSteps: '/rpa/run/:runId',
  postRpaRun: '/rpa/run',
  postRpaRunNow: '/rpa/run/now',
  postRpaRetryStepById: '/rpa/run/retry/:stepId',
} as const

export const endpointsSteps = {
  getSteps: '/rpa/steps',
} as const

export const endpointsEmail = {
  getEmailDetails: '/v1/email/:emailId/details',
  getEmailConfig: '/v1/email/:emailId/config',
  getEmailAttachements: '/v1/email/:emailId/attachements',
  postEmailTest: '/v1/email/test-email',
} as const

export const endpointsRPA = {
  getCompanyRPA: '/rpa/company/:companyId',
  postStartRPA: '/rpa/start/:companyId?userId=:userId',
  postRestartRPA: '/rpa/restart/:companyId?userId=:userId',
  postStopRPA: '/rpa/stop/:companyId?userId=:userId',
  getHistoryRPA: '/rpa/:rpaId/history',
} as const

export const endpoinstConneciton = {
  postConnectionTest: '/v1/:typeConfig/credential',
  postTestEmail: '/v1/email/test-email',
} as const
