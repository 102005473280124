/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react'

import {COLORS, If, Text} from '@intraversa-lab/styleguide'
import {
  Table as TableMui,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  TableSortLabel,
  TablePagination,
  CircularProgress,
  Box,
} from '@mui/material'
import {makeStyles} from '@mui/styles'

interface ITableProps {
  data: any[]
  columns: {
    label: string
    column: string
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit'
    format?: (value: any) => any
    renderCell?: (value, row: any) => any
  }[]
  tableSelected?: boolean
  Header?: any
  padding?: string
  value?: string
  onChange?: (value: string) => void
  isLoading?: boolean
  paginable?: boolean
  paginationProps?: {
    page: number
    offset: number
    total: number
  }
  handleChangePage?: (page: number) => void
  handleChangeRowsPerPage?: (perPage: number) => void
}

type Order = 'asc' | 'desc'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    background: '#FFF',
    padding: '16px 24px 24px !important',
    borderRadius: '8px !important',
    boxShadow: 'none !important',
    minHeight: 400,
  },
  root2: {
    background: '#FFF',
    padding: '24px !important',
    borderRadius: '8px !important',
    boxShadow: 'none !important',
    minHeight: 400,
  },
  stripedRow: {
    backgroundColor: '#F9F9F9',
  },
  column: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: '#3C454D',
    padding: '8px !important',
    fontSize: '14px',
    fontWeight: 400,
    borderBottom: 'none !important',
  },
  columnWhenLeft: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: '#3C454D',
    padding: '8px 8px 8px 16px !important',
    fontSize: '14px',
    fontWeight: 400,
    borderBottom: 'none !important',
  },
  columnHeader: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: '#161F28 !important',
    padding: '8px 16px 16px !important',
    fontSize: '12px',
    fontWeight: 500,
    borderBottom: 'none !important',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px !important',
  },
  selected: {
    backgroundColor: '#F1ECFF !important',
  },
  purpleRadio: {
    width: '12px !important',
    height: '12px !important',
    color: '#75808A !important',
    '&.Mui-checked': {
      color: '#6D42E1 !important',
    },
  },
})

function descendingComparator(a: any, b: any, orderBy: keyof any): number {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(
  order: Order,
  orderBy: keyof any,
): (a: any, b: any) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(
  array: any[],
  comparator: (a: any, b: any) => number,
): any[] {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const ArrowDownwardIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.0013 9.99977L4.66797 6.68311H11.3346L8.0013 9.99977Z"
      fill="#161F28"
    />
  </svg>
)

const ArrowUpwardIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.83203 11.6667L9.9987 7.47916L14.1654 11.6667H5.83203Z"
      fill="#6D42E1"
    />
  </svg>
)

export const Table: React.FC<ITableProps> = ({
  data,
  columns,
  tableSelected = false,
  Header,
  padding = '24px',
  value: selectedValue,
  onChange,
  isLoading = false,
  paginable = false,
  paginationProps = {
    page: 0,
    offset: 10,
    total: 10,
  },
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const classes = useStyles()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof any>('')

  const handleChange = (value: string) => {
    if (onChange) {
      onChange(String(value))
    }
  }

  const handleRequestSort = (property: keyof any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function getValueByPath(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj)
  }

  const SortIcon = order === 'desc' ? ArrowUpwardIcon : ArrowDownwardIcon

  return (
    <TableContainer
      component={Paper}
      className={padding === '24px' ? classes.root2 : classes.root}>
      {Header && <Header />}

      <TableMui className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableSelected && (
              <TableCell className={classes.columnHeader}></TableCell>
            )}
            {columns.map((item) => (
              <TableCell
                align={item?.align ?? 'center'}
                className={classes.columnHeader}
                key={item.column}>
                <TableSortLabel
                  active={orderBy === item.column}
                  direction={order}
                  onClick={() => handleRequestSort(item.column)}
                  IconComponent={SortIcon}>
                  <span className={classes.headerContent}>{item.label}</span>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <If condition={isLoading}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <CircularProgress size={50} />
          </Box>
        </If>
        <If condition={!isLoading && !!data?.length}>
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map(
              (row, index) => (
                <TableRow
                  key={String(index)}
                  onClick={() => handleChange(String(row?.id))}
                  selected={selectedValue === String(index)}
                  className={`${index % 2 ? '' : classes.stripedRow} ${selectedValue === String(row?.id) ? classes.selected : ''}`}>
                  {tableSelected && (
                    <TableCell padding="checkbox" className={classes.column}>
                      <Radio
                        checked={selectedValue === String(row?.id)}
                        onChange={() => handleChange(String(row?.id))}
                        value={String(index)}
                        className={classes.purpleRadio}
                        name="radio-button-demo"
                        inputProps={{'aria-label': String(index)}}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 16,
                          },
                        }}
                      />
                    </TableCell>
                  )}

                  {columns.map((column) => (
                    <TableCell
                      component="th"
                      scope="row"
                      className={
                        column?.align === 'left'
                          ? classes.columnWhenLeft
                          : classes.column
                      }
                      align={column?.align ?? 'center'}
                      style={{
                        paddingLeft: column?.align ? '24px !important' : '0px',
                      }}
                      key={column.column}>
                      {column?.format
                        ? column.format(getValueByPath(row, column.column))
                        : column?.renderCell
                          ? column.renderCell(
                              getValueByPath(row, column.column),
                              row,
                            )
                          : getValueByPath(row, column.column)}
                    </TableCell>
                  ))}
                </TableRow>
              ),
            )}
          </TableBody>

          <If condition={paginable}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={tableSelected ? columns.length + 1 : columns.length}
              rowsPerPage={paginationProps?.offset}
              page={paginationProps?.page}
              count={paginationProps?.total}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
              labelRowsPerPage="Registros por página"
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'Registros por página',
                  },
                  native: true,
                },
              }}
              onPageChange={(_, newPage) => {
                handleChangePage && handleChangePage(newPage)
              }}
              onRowsPerPageChange={(event) => {
                handleChangeRowsPerPage &&
                  handleChangeRowsPerPage(parseInt(event.target.value, 10))
                handleChangePage && handleChangePage(0)
              }}
            />
          </If>
        </If>
        <If condition={!isLoading && !data?.length}>
          <Text
            type="labelLg"
            style={{
              color: COLORS.shade[10],
              fontWeight: '500',
              marginLeft: 16,
            }}>
            Nenhum registro encontrado
          </Text>
        </If>
      </TableMui>
    </TableContainer>
  )
}
