import React, {useMemo} from 'react'

import {If} from '@intraversa-lab/styleguide'
import {Box, CircularProgress} from '@mui/material'
import Tab from '@mui/material/Tab'
import TabsMUI from '@mui/material/Tabs'
import {HeaderContent} from 'components/HeaderContent/HeaderContent'
import {
  IResponseHistory,
  useGetHistory,
  useGetNextExecution,
  useGetSteps,
} from 'domains'
import {useGetConfigRpa} from 'domains/RpaConfig/useCases'
import {useIsAdmin} from 'hooks/useIsAdmin'
import moment from 'moment'
import {useParams} from 'react-router-dom'

import {ConfigContainer} from './containers/Configs/config-container'
import {IntegrationsContainter} from './containers/Integrations/Integrations'
import * as S from './styles'

const ScheduledPage = () => {
  const [value, setValue] = React.useState('integration')
  const [historyParams, setHistoryParams] = React.useState({
    page: 0,
    offset: 10,
    total: 10,
  })

  const isAdmin = useIsAdmin()
  const {rpaId} = useParams()
  const {data: rpaConfig, isLoading: isLoadingConfigRpa} = useGetConfigRpa({
    rpaId,
    enabled: true,
  })
  const {
    data: historyData,
    dataUpdatedAt,
    isLoading: isLoadingHistory,
    isFetching,
  } = useGetHistory(historyParams, {
    onSuccess: (data: IResponseHistory) => {
      setHistoryParams((old) => ({...old, total: data.total}))
    },
  })
  const {isLoading: isLoadingNextExecution} = useGetNextExecution()
  const {isLoading: isLoadingSteps} = useGetSteps()

  const isLoading = useMemo(() => {
    return (
      isLoadingConfigRpa ||
      isLoadingHistory ||
      isLoadingNextExecution ||
      isLoadingSteps ||
      isFetching
    )
  }, [
    isLoadingConfigRpa,
    isLoadingHistory,
    isLoadingNextExecution,
    isLoadingSteps,
  ])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const renderUpdatedLast = () => {
    if (value === 'configs') {
      return rpaConfig?.updatedLast
    }

    const dateHistory = new Date(dataUpdatedAt).toString()

    return moment(dateHistory).format('DD/MM/YYYY HH:mm:ss')
  }

  return (
    <S.Wrapper>
      <HeaderContent updatedLast={renderUpdatedLast()} />

      <S.WrapperTabs>
        <If condition={isAdmin}>
          <TabsMUI value={value} onChange={handleChange}>
            <Tab value="integration" label="Integrações" />

            <Tab
              value="configs"
              label="Configurações"
              style={{marginLeft: 32}}
            />
          </TabsMUI>
        </If>

        <If condition={!isAdmin}>
          <TabsMUI value={value} onChange={handleChange}>
            <Tab value="integration" label="Integrações" />
          </TabsMUI>
        </If>
      </S.WrapperTabs>

      <S.Content>
        <If condition={isLoading}>
          <Box
            display="flex"
            alignItems={'center'}
            justifyContent="center"
            flex={1}
            style={{height: '100%'}}
            mt="5">
            <CircularProgress size={50} />
          </Box>
        </If>
        <If condition={!isLoading}>
          <If condition={value === 'integration'}>
            <IntegrationsContainter
              setHistoryParams={setHistoryParams}
              historyParams={historyParams}
              historyData={historyData}
            />
          </If>

          <If condition={value === 'configs'}>
            <ConfigContainer rpaConfig={rpaConfig} />
          </If>
        </If>
      </S.Content>
    </S.Wrapper>
  )
}

export default ScheduledPage
