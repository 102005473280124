export const ENUM_TYPES_SCHEDULER_CONFIG = {
  GALAXY_PAY: 'GALAXY_PAY',
  EMAIL: 'EMAIL',
  CONTA_AZUL: 'CONTA_AZUL',
  PORTAL: 'PORTAL',
} as const;

export const EMAIL_TYPE = {
  CONNECTION: 'CONNECTION',
  AUTOMATIC: 'AUTOMATIC',
};

export const ENUM_REQUEST_TEST_CONNECTION = {
  'galaxy-pay': 'galaxy-pay',
  portal: 'portal',
  'conta-azul': 'conta-azul',
  email: 'email',
};

export type ENUM_TYPES_SCHEDULER_CONFIG_VALUES =
  (typeof ENUM_TYPES_SCHEDULER_CONFIG)[keyof typeof ENUM_TYPES_SCHEDULER_CONFIG];

export type ENUM_EMAIL_TYPE = keyof typeof EMAIL_TYPE;
export type TYPE_TEST_CONNECTION = keyof typeof ENUM_REQUEST_TEST_CONNECTION;

type SchedulerConfigField = {
  name: string;
  label: string;
  typeInput: 'text' | 'password';
  typeEmail?: ENUM_EMAIL_TYPE;
  multiline?: number;
  showIconPassword?: boolean;
};

export interface ISchedulerConfigBase {
  type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES;
  title: string;
  titleModal?: string;
  apiConnection: TYPE_TEST_CONNECTION;
  descriptionModal?: string;
  containers?: number;
  titleModal2?: string;
  descriptionModal2?: string;
  fields: SchedulerConfigField[];
}

export const schedulerConfigTypes: ISchedulerConfigBase[] = [
  {
    type: ENUM_TYPES_SCHEDULER_CONFIG.GALAXY_PAY,
    title: 'Galaxy Pay',
    titleModal: 'Credenciais Galaxy Pay',
    apiConnection: 'galaxy-pay',
    descriptionModal:
      'Altere os dados de sua credencial Galaxy Pay preenchendo os campos abaixo.',
    fields: [
      {
        name: 'username',
        label: 'Galaxy ID',
        typeInput: 'text',
      },
      {
        name: 'password',
        label: 'Galaxy Hash',
        typeInput: 'password',
        showIconPassword: false,
      },
    ],
  },
  {
    type: ENUM_TYPES_SCHEDULER_CONFIG.CONTA_AZUL,
    title: 'Conta Azul',
    apiConnection: 'conta-azul',
    titleModal: 'Credenciais Conta Azul',
    descriptionModal:
      'Altere os dados de sua credencial Conta Azul preenchendo os campos abaixo.',
    fields: [
      {
        name: 'username',
        label: 'Client ID',
        typeInput: 'text',
      },
      {
        name: 'password',
        label: 'Client Secret',
        typeInput: 'password',
        showIconPassword: false,
      },
    ],
  },
  {
    type: ENUM_TYPES_SCHEDULER_CONFIG.PORTAL,
    title: 'Portal',
    apiConnection: 'portal',
    titleModal: 'Credenciais Portal',
    descriptionModal:
      'Altere os dados de sua credencial do Portal preenchendo os campos abaixo.',
    fields: [
      {
        name: 'username',
        label: 'Usuário',
        typeInput: 'text',
      },
      {
        name: 'password',
        label: 'Senha',
        typeInput: 'password',
        showIconPassword: false,
      },
      {
        name: 'password',
        label: 'Senha primeiro acesso',
        typeInput: 'password',
        showIconPassword: false,
      },
    ],
  },
  {
    type: ENUM_TYPES_SCHEDULER_CONFIG.EMAIL,
    title: 'Email',
    apiConnection: 'email',
    containers: 2,
    titleModal: 'Conexões de Email',
    descriptionModal:
      'Altere os dados de suas conexões de email preenchendo os campos abaixo.',
    titleModal2: 'Emails automáticos',
    descriptionModal2:
      'Edite o e-mail automático que é enviado aos seus clientes após o cadastro.',
    fields: [
      {
        name: 'smtpServer',
        label: 'Servidor Smtp',
        typeInput: 'text',
        typeEmail: EMAIL_TYPE.CONNECTION as ENUM_EMAIL_TYPE,
      },
      {
        name: 'smtpPort',
        label: 'Porta Servidor Smtp',
        typeInput: 'text',
        typeEmail: EMAIL_TYPE.CONNECTION as ENUM_EMAIL_TYPE,
      },
      {
        name: 'smtpUsername',
        label: 'Usuário Smtp',
        typeInput: 'text',
        typeEmail: EMAIL_TYPE.CONNECTION as ENUM_EMAIL_TYPE,
      },
      {
        name: 'smtpPassword',
        label: 'Senha Usuário Smtp',
        typeInput: 'password',
        showIconPassword: false,
        typeEmail: EMAIL_TYPE.CONNECTION as ENUM_EMAIL_TYPE,
      },
      {
        name: 'ref_smtp',
        label: 'Remetente',
        typeInput: 'text',
        typeEmail: EMAIL_TYPE.CONNECTION as ENUM_EMAIL_TYPE,
      },
      {
        name: 'emailSubject',
        label: 'Assunto',
        typeInput: 'text',
        typeEmail: EMAIL_TYPE.AUTOMATIC as ENUM_EMAIL_TYPE,
      },
      {
        name: 'emailCcs',
        label: 'Em cópia',
        typeInput: 'text',
        typeEmail: EMAIL_TYPE.AUTOMATIC as ENUM_EMAIL_TYPE,
      },
      {
        name: 'emailBody',
        label: 'Corpo do email',
        typeInput: 'text',
        typeEmail: EMAIL_TYPE.AUTOMATIC as ENUM_EMAIL_TYPE,
        multiline: 4,
      },
    ],
  },
];
