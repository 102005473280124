import {QueryKeys} from 'infra'
import moment from 'moment'
import {useQuery} from 'react-query'

import {rpaConfigApi} from '../rpaConfigApi'
import {IRpaConfigResponse} from '../rpaConfigTypes'

export function useGetConfigRpa({
  rpaId,
  enabled = false,
}: {
  rpaId: string
  enabled?: boolean
}) {
  const {data, isLoading, isError, refetch, isFetching} =
    useQuery<IRpaConfigResponse>({
      queryKey: [QueryKeys.GetConfigRpa],
      queryFn: async () => {
        try {
          const {
            data,
          }: {
            data: {
              id: string
              rpaId: string
              config: string
            }
          } = await rpaConfigApi.getRpaConfigByRpaId(rpaId)

          const {id, config} = data || {}
          const newResponse = {
            id,
            rpaId,
            config: JSON.parse(config),
            updatedLast: moment().format('DD/MM/YYYY HH:mm:ss'),
          }
          return newResponse
        } catch (e) {
          throw new Error(e)
        }
      },
      staleTime: 1000 * 30,
      enabled,
    })

  return {
    data,
    isLoading,
    isFetching,
    isError,
    refetch,
  }
}
