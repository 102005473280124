import {toast} from '@intraversa-lab/styleguide'

import {rpaApi} from './rpaApi'
import {ICompanyRPA, IGetRPAHistory} from './rpaTypes'

async function getCompanyRPA({
  companyId,
  page = 0,
  offset = 10,
  name,
  active,
  sort,
}: {
  companyId: string
  page: number
  offset: number
  name: string
  active?: boolean
  sort: string
}): Promise<ICompanyRPA[]> {
  try {
    const respose = await rpaApi.getCompanyRPA({
      companyId,
      page,
      offset,
      name,
      active,
      sort,
    })

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao buscar RPAs.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function getHistoryRPA(rpaId: string): Promise<IGetRPAHistory[]> {
  try {
    const respose = await rpaApi.getHistoryRPA(rpaId)

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao buscar historico de rpa.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function postStartRPA({
  companyId,
  userId,
}: {
  companyId: string
  userId: string
}): Promise<ICompanyRPA[]> {
  try {
    const respose = await rpaApi.postStartRPA({
      companyId,
      userId,
    })

    toast({
      type: 'success',
      text: 'RPA startado com sucesso.',
    })

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao startar RPA.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function postRestartRPA({
  companyId,
  userId,
}: {
  companyId: string
  userId: string
}): Promise<ICompanyRPA[]> {
  try {
    const respose = await rpaApi.postRestartRPA({
      companyId,
      userId,
    })

    toast({
      type: 'success',
      text: 'RPA restartado com sucesso.',
    })

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao restartar RPA.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function postStopRPA({
  companyId,
  userId,
}: {
  companyId: string
  userId: string
}): Promise<ICompanyRPA[]> {
  try {
    const respose = await rpaApi.postStopRPA({
      companyId,
      userId,
    })

    toast({
      type: 'success',
      text: 'RPA parado com sucesso.',
    })

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao parar RPA.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

export const rpaService = {
  getCompanyRPA,
  postStartRPA,
  postRestartRPA,
  postStopRPA,
  getHistoryRPA,
}
