/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCallback, useEffect, useMemo, useState} from 'react';

import {COLORS, Text, Select, Tag} from '@intraversa-lab/styleguide';
import {IconAgenda} from 'assets/Agenda';
import {Table} from 'components/Table';
import {queryClient} from 'configs/queryClient';
import {
  IResponseHistory,
  IResponseNextExecution,
  IResponseSteps,
} from 'domains';
import {IRpaConfigResponse} from 'domains/RpaConfig/rpaConfigTypes';
import {useGetStepDetails} from 'domains/RpaRun/useCases/useGetDetails';
import {QueryKeys} from 'infra';
import moment from 'moment';
import {typeSchedulerLabel} from 'utils';
import {schedulerFormatDataComplete} from 'utils/schedulerDate';

import * as S from './styles';

interface IIntegrationsContainer {
  setHistoryParams: React.Dispatch<
    React.SetStateAction<{
      page: number;
      offset: number;
      total: number;
    }>
  >;
  historyParams: {
    page: number;
    offset: number;
    total: number;
  };
  historyData: IResponseHistory;
}

export const IntegrationsContainter = ({
  historyParams,
  setHistoryParams,
  historyData,
}: IIntegrationsContainer) => {
  const [stepSelected, setStepSelected] = useState('');
  const [rpaRunSelected, setRpaRunSelected] = useState('');

  const {data: stepDetails} = useGetStepDetails(
    {
      rpaId: rpaRunSelected,
      step: stepSelected,
    },
    {},
  );

  const nextExecutionData: IResponseNextExecution =
    queryClient.getQueryData(QueryKeys.GetNextExecution) ||
    ({} as IResponseNextExecution);

  const configsData: IRpaConfigResponse =
    queryClient.getQueryData(QueryKeys.GetConfigRpa) ||
    ({} as IRpaConfigResponse);

  const stepsData: IResponseSteps[] =
    queryClient.getQueryData(QueryKeys.GetSteps) || [];

  const handleSelectedStep = useCallback(
    (option: {label: string; value: string}) => {
      setStepSelected(option.value);
    },
    [],
  );

  const optionsSteps = useMemo(() => {
    return (
      stepsData?.map(item => ({
        value: String(item.id),
        label: item.description,
      })) || []
    );
  }, [stepsData]);

  const formatValue = (value: any) => {
    return value !== null && !!value ? value : '-';
  };

  const columns: any = useMemo(() => {
    const renderDefault = ['1', '2'];
    const isDefault = renderDefault.includes(stepSelected);

    if (isDefault) {
      return [
        {
          label: 'Empresa',
          column: 'company',
          align: 'left',
          format: formatValue,
        },
        {label: 'CNPJ', column: 'document', format: formatValue},
        {label: 'Plano', column: 'plan', format: formatValue},
      ];
    }

    if (stepSelected === '3') {
      return [
        {
          label: 'Empresa',
          column: 'company',
          align: 'left',
          format: formatValue,
        },
        {label: 'CNPJ', column: 'document', format: formatValue},
        {label: 'Plano', column: 'plan', format: formatValue},
        {
          label: 'Vendas Criadas',
          column: 'contaAzulSales',
          format: formatValue,
        },
      ];
    }

    return [
      {
        label: 'Cliente',
        column: 'portal.client',
        align: 'left',
        format: formatValue,
      },
      {
        label: 'Usuário',
        column: 'portal.user',
        align: 'left',
        format: formatValue,
      },
      {label: 'Senha', column: 'portal.password', format: formatValue},
      {label: 'Projeto', column: 'portal.project', format: formatValue},
    ];
  }, [stepSelected]);

  useEffect(() => {
    if (stepsData) {
      setStepSelected(String(stepsData[0].id));
    }

    if (historyData?.data?.length) {
      setRpaRunSelected(String(historyData?.data?.[0]?.id));
    }
  }, [historyData]);

  return (
    <S.Wrapper>
      <S.WrapperDescription>
        <p
          style={{
            fontFamily: 'Roboto',
            fontWeight: 600,
            fontSize: '24px',
            color: '#161F28',
            margin: 0,
            padding: 0,
            marginTop: 32,
          }}
        >
          Próxima Execução
        </p>

        <div className="description-content">
          <div className="description-content__item">
            <div className="item__title">
              <IconAgenda />

              <p className="tile_text">Programado para</p>
            </div>

            <Text
              type="labelLg"
              style={{
                marginLeft: 24,
                color: COLORS.shade[10],
                marginTop: 8,
                fontWeight: 500,
              }}
            >
              {schedulerFormatDataComplete(nextExecutionData?.nextRun)}
            </Text>
          </div>

          <div className="description-content__item">
            <div className="item__title">
              <IconAgenda />

              <p className="tile_text">Scheduler</p>
            </div>

            <Text
              type="labelLg"
              style={{
                marginLeft: 24,
                color: COLORS.shade[10],
                marginTop: 8,
                fontWeight: 500,
              }}
            >
              {typeSchedulerLabel(
                configsData?.config?.scheduler?.typeScheduler,
              )}
            </Text>
          </div>
        </div>
      </S.WrapperDescription>

      {!!historyData?.data?.length && (
        <S.WrapperExecutions>
          <p
            style={{
              fontFamily: 'Roboto',
              fontWeight: 600,
              fontSize: '24px',
              color: '#161F28',
              margin: 0,
              padding: 0,
              marginTop: 32,
            }}
          >
            Últimas execuções
          </p>

          <Text
            type="bodyLg"
            marginTop={'8px'}
            marginBottom={'24px'}
            style={{color: '#59636B'}}
          >
            Selecione uma execução e visualize seus detalhes em etapas
          </Text>

          <Table
            tableSelected
            value={rpaRunSelected}
            onChange={value => setRpaRunSelected(value)}
            columns={[
              {
                label: 'Horário',
                column: 'createdAt',
                align: 'left',
                format: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
              },
              {label: 'Nº Contratos Cadastrados', column: 'success'},
              {label: 'Erros', column: 'errors'},
              {
                label: 'Execução Manual',
                column: 'manual',
                format: value => (value ? 'Sim' : 'Não'),
              },
            ]}
            data={historyData?.data || []}
            paginable
            paginationProps={historyParams}
            handleChangePage={page => setHistoryParams(old => ({...old, page}))}
            handleChangeRowsPerPage={offset =>
              setHistoryParams(old => ({...old, offset}))
            }
          />

          <div className="wrapper-table" style={{minHeight: 400}}>
            <Table
              padding={'24px'}
              columns={columns}
              data={stepDetails || []}
              Header={() => (
                <S.WrapperStep>
                  <p className="WrapperStep--Title">Etapa</p>

                  <div className="WrapperStep--Select">
                    <Select
                      placeholder={'Selecione a permissão'}
                      options={optionsSteps}
                      onChange={handleSelectedStep}
                      value={optionsSteps?.find(
                        option => option.value === stepSelected,
                      )}
                    />
                  </div>
                </S.WrapperStep>
              )}
            />
          </div>
        </S.WrapperExecutions>
      )}
    </S.Wrapper>
  );
};
